import { Component, OnInit } from '@angular/core';
import { Lightbox } from 'ngx-lightbox';

@Component({
  selector: 'app-home',
  template: `
  <section id="about" style="position: relative;">
    <div id="about-anchor" style="position: absolute; top: -86px; left: 0"></div>
    <div class="container">
      <div class="row align-items-center justify-content-center">
        <img src="../../assets/img/domowik_logo_biale.png" class="w-50">
        <div class="container">
          <p class="text-center text-white mx-auto lead">
            W Jałowem, w sercu Bieszczad stoi dom z pocztówkowym widokiem. Swoją nazwę zawdzięcza 
            słowiańskiemu duszkowi opiekującemu się domem - Domowikowi, który, jak wierzymy, czuwa 
            również nad tym miejscem i jego mieszkańcami. 
          </p>
          <p class="text-center text-white mx-auto lead">
            Dom spodoba się każdemu,
            kto szuka odrobiny spokoju. Ten znaleźć można na leżaku na tarasie, w balii z gorącą lub
            chłodną wodą, przy kominku, ognisku lub na kwiecistej łące porastającej zbocze.
          </p>
          <div class="row justify-content-center py-5">
            <a (click)="scrollToContact()" class="btn rounded-0 bg-white px-5">ZAREZERWUJ</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  `,
  styles: [
    `
      h1 {
        font-family: 'acier';
        line-height: 0.7;
        font-size: calc(36px + 2vw);
      }

      p {
        font-family: 'lato-light';
        font-size: calc(16px + 0.5vw);
      }

      a {
        cursor: pointer;
      }

      #about {
        background-image: url("../../assets/img/domowik_dom_bieszczady_home.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
      }
    `
  ]
})
export class HomeComponent implements OnInit {

  latitude = 49.410278;
  longitude = 22.631449;


  _albums = [] as any;
  constructor(private _lightbox: Lightbox) {
    for (let i = 1; i <= 12; i++) {
      const src = '../../assets/img/' + i + '.jpg';
      const album = {
         src: src,
      };
 
      this._albums.push(album);
    }
  }
 
  open(index: number): void {
    this._lightbox.open(this._albums, index);
  }
 
  close(): void {
    this._lightbox.close();
  }

  ngOnInit(): void {
  }

  scrollToContact() {
    document.getElementById("contact-anchor")?.scrollIntoView({behavior: "smooth"});
  }
}
