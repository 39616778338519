import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dom',
  template: `
    <section id="home" style="position: relative;" class="py-5">
      <div id="home-anchor" style="position: absolute; top: -86px; left: 0"></div>
      <div class="row px-5">
        <div class="col-lg-6">
          <h1 class="py-5">DOM</h1>
          <p>
            Domowik to dom przeznaczony dla 4-6 osób. Na
            parterze zlokalizowany jest przedpokój, łazienka,
            2 sypialnie z łóżkami małżeńskimi, przestronny, klimatyzowany
            salon z kominkiem oraz wyjściem na 50-metrowy taras i kuchnia.
            Trzecia sypialnia, z dwoma pojedynczymi łóżkami (z możliwością połączenia) oraz toaletą mieści się na poddaszu.
          </p>
          <p>
            Do dyspozycji gości pozostaje miejsce na ognisko
            z ławkami, balia ogrodowa z gorącą lub chłodną wodą oraz 30-arowa,
            ogrodzona działka.
          </p>
          <p>
            Dom wyposażony jest w pościel, ręczniki, suszarkę
            do włosów, podstawowe kosmetyki, telewizor,
            szybki Internet wi-fi oraz wszystko, co niezbędne w
            kuchni- od lodówki i piekarnika po garnki, patelnie,
            zastawę stołową, sztućce czy włoską kawiarkę.
          </p>
        </div>
      </div>
    </section>
  `,
  styles: [
    `
      @media (min-width: 992px) {
        #home {
          background-image: url("../../assets/img/domowik_dom_bieszczady_dom.jpg");
          background-size: 50% 85%;
          background-repeat: no-repeat;
          background-position: right center;
        }
      }
    `
  ]
})
export class DomComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
