import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-regulations',
  template: `
    <section id="regulations" style="position: relative;" class="pb-5">
      <div id="regulations-anchor" style="position: absolute; top: -86px; left: 0"></div>
      <div class="row px-5">
        <div class="col-lg-6  offset-lg-6">
          <h1 class="py-5">WARUNKI REZERWACJI</h1>
          <p>
            1. Złożenie zamówienia (rezerwacji) noclegów
            w Domowiku jest równoznaczne z zaakceptowaniem
            <a href="../../assets/files/REGULAMIN%20Domowik.pdf" target="_blank">regulaminu</a>.<br>
            2. Obiekt wynajmowany jest min. na 3 doby, zgodnie
            ze złożoną wcześniej rezerwacją.<br>
            3. Doba trwa od godz. 16:00 w dniu przyjazdu
            do godz. 10:00 ostatniego dnia pobytu.<br>
            4. Rezerwacji noclegów można dokonywać, pocztą
            elektroniczną lub telefonicznie. Potwierdzenie
            rezerwacji ma zawsze miejsce za pośrednictwem
            poczty elektronicznej
            (adres: rezerwacje@domowik-bieszczady.pl).<br>
            5. Ostateczne potwierdzenie rezerwacji następuje
            po wpłacie zadatku w wysokości 30% należności za pobyt na konto
            bankowe podane w wiadomości z kalkulacją.
            Wpłacony zadatek nie podlega zwrotowi.<br>
            6. Należność za cały pobyt (pomniejszona o wpłacony
            zadatek) powinna zostać opłacona najpóźniej 3 dni
            przed przyjazdem.
          </p>
        </div>
      </div>
    </section>
  `,
  styles: [
    ` 
    h1 {
      line-height: 0.7;
    }
    

    @media (min-width: 992px) {
      #regulations {
        background-image: url("../../assets/img/domowik_dom_bieszczady_warunki_rezerwacji.png");
        background-size: 50% 85%;
        background-repeat: no-repeat;
        background-position: left center;
      }
      
      .row {
        margin-left: 1rem !important;
      }
    }
    `
  ]
})
export class RegulationsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
