import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  template: `
  <nav class="navbar navbar-expand-md navbar-light bg-white fixed-top">
    <a class="navbar-brand" href="#">
      <img style="height: 60px;" src="../../assets/img/domowik_minilogo_czarne.png">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav w-100 justify-content-around">
            <li class="nav-item">
              <a class="nav-link text-nowrap lato-light" data-toggle="collapse" data-target=".navbar-collapse.show" (click)="scrollToAbout()">O nas</a>
            </li>
            <li class="nav-item ">
                <a class="nav-link lato-light" data-toggle="collapse" data-target=".navbar-collapse.show" (click)="scrollToHome()">Dom</a>
            </li>
            <li class="nav-item">
                <a class="nav-link lato-light" data-toggle="collapse" data-target=".navbar-collapse.show" (click)="scrollToGalery()">Galeria</a>
            </li>
            <li class="nav-item">
                <a class="nav-link lato-light" data-toggle="collapse" data-target=".navbar-collapse.show" (click)="scrollToPrices()">Cennik</a>
            </li> 
            <li class="nav-item">
                <a class="nav-link text-nowrap  lato-light" data-toggle="collapse" data-target=".navbar-collapse.show" (click)="scrollToRegulatons()">Warunki rezerwacji</a>
            </li>
            <li class="nav-item">
                <a class="nav-link lato-light" data-toggle="collapse" data-target=".navbar-collapse.show" (click)="scrollToContact()">Kontakt</a>
            </li>
        </ul>
    </div>
    <a id="reserve" class="btn btn-outline-dark rounded-0 d-none d-lg-block lato" (click)="scrollToContact()">ZAREZERWUJ</a>
  </nav>
  `,
  styles: [
    `
    .lato {
      font-family: 'lato-regular';
    }

    .lato-light {
      font-family: 'lato-light';
    }
    
    a:hover {
      cursor: pointer;
    }
    
    #reserve:hover {
      color: white;
    }
    `
  ]
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  scrollToHome() {
    document.getElementById("home-anchor")?.scrollIntoView({behavior: "smooth"});
  }

  scrollToAbout() {
    document.getElementById("about-anchor")?.scrollIntoView({behavior: "smooth"});
  }

  scrollToGalery() {
    document.getElementById("galery-anchor")?.scrollIntoView({behavior: "smooth"});
  }

  scrollToPrices() {
    document.getElementById("prices-anchor")?.scrollIntoView({behavior: "smooth"});
  }

  scrollToRegulatons() {
    document.getElementById("regulations-anchor")?.scrollIntoView({behavior: "smooth"});
  }

  scrollToContact() {
    document.getElementById("contact-anchor")?.scrollIntoView({behavior: "smooth"});
  }
  
}
