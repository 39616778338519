import { Component, OnInit } from '@angular/core';
import { Lightbox } from 'ngx-lightbox';

@Component({
  selector: 'app-galery',
  template: `
    <section id="galery" style="position: relative;" class="pb-5">
      <div id="galery-anchor" style="position: absolute; top: -86px; left: 0"></div>
      <div class="row pl-5 pr-5 mr-3">
        <div class="col-lg-6">
          <h1 class="py-5">GALERIA</h1>
        </div>
      </div>
      <div class="mx-auto" style="width: 80%">
        <div class="row mx-2">
          <div class="column mx-auto row">
            <img [src]="_albums[0].src" (click)="open(0)"/>
            <img [src]="_albums[5].src" (click)="open(5)"/>
            <img [src]="_albums[19].src" (click)="open(19)"/>
            <img [src]="_albums[17].src" (click)="open(17)"/>
            <img [src]="_albums[10].src" (click)="open(10)"/>
          </div>
          <div class="column mx-auto row">
            <img [src]="_albums[1].src" (click)="open(1)"/>
            <img [src]="_albums[6].src" (click)="open(6)"/>
            <img [src]="_albums[14].src" (click)="open(20)"/>
            <img [src]="_albums[7].src" (click)="open(7)"/>
            <img [src]="_albums[11].src" (click)="open(11)"/>
          </div>
          <div class="column mx-auto row">
            <img [src]="_albums[2].src" (click)="open(2)"/>
            <img [src]="_albums[3].src" (click)="open(3)"/>
            <img [src]="_albums[15].src" (click)="open(21)"/>
            <img [src]="_albums[8].src" (click)="open(8)"/>
            <img [src]="_albums[12].src" (click)="open(12)"/>
          </div>
          <div class="column mx-auto row">
            <img [src]="_albums[4].src" (click)="open(12)"/>
            <img [src]="_albums[18].src" (click)="open(13)"/>
            <img [src]="_albums[16].src" (click)="open(14)"/>
            <img [src]="_albums[9].src" (click)="open(15)"/>
            <img [src]="_albums[13].src" (click)="open(3)"/>
          </div>
        </div>
      </div>
    </section>
  `,
  styles: [
    `
    .row {
      display: flex;
      flex-wrap: wrap;
    }

    /* Create four equal columns that sits next to each other */
    .column {
      flex: 25%;
      max-width: 25%; 
      /*padding: 0 4px;*/
    }

    .column img {
      margin: 4px;
      vertical-align: middle;
      width: 100%;
      border-radius: 5px;
      cursor: pointer;
      transition: 0.3s linear;
    }

    @media screen and (max-width: 800px) {
      .column {
        flex: 50%;
        max-width: 50%;
      }
    }

    /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
    @media screen and (max-width: 600px) {
      .column {
        flex: 100%;
        max-width: 100%;
      }
    }

    ` 
  ]
})
export class GaleryComponent implements OnInit {

  _imgsources = [] as any;
  _albums = [] as any;

  constructor(private _lightbox: Lightbox) {

    // this._imgsources.push('../../assets/img/gallery/domowik_bieszczady_dom.jpeg');
    // this._imgsources.push('../../assets/img/gallery/domowik_bieszczady_dom_2.jpg');
    // this._imgsources.push('../../assets/img/gallery/domowik_bieszczady_balia.jpeg');
    // this._imgsources.push('../../assets/img/gallery/domowik_bieszczady_dom_3.jpg');
    // this._imgsources.push('../../assets/img/gallery/domowik_bieszczady_salon_2.jpg');
    // this._imgsources.push('../../assets/img/gallery/domowik_bieszczady_palenisko.jpeg');
    // this._imgsources.push('../../assets/img/gallery/domowik_bieszczady_salon_3.jpg');
    // this._imgsources.push('../../assets/img/gallery/domowik_bieszczady_kuchnia.jpg');
    // this._imgsources.push('../../assets/img/gallery/domowik_bieszczady_pokoj_taras.jpeg');
    // this._imgsources.push('../../assets/img/gallery/domowik_bieszczady_pokoj_taras_2.jpg');
    // this._imgsources.push('../../assets/img/gallery/domowik_bieszczady_pokoj.jpeg');
    // this._imgsources.push('../../assets/img/gallery/domowik_bieszczady_lazienka.jpg');
    // this._imgsources.push('../../assets/img/gallery/domowik_bieszczady_przedpokoj.jpg');
    // this._imgsources.push('../../assets/img/gallery/domowik_bieszczady_toaleta_poddasze.jpg');
    // this._imgsources.push('../../assets/img/gallery/domowik_bieszczady_pokoj_poddasze.jpeg');
    // this._imgsources.push('../../assets/img/gallery/domowik_bieszczady_dom_front.jpg');
    // this._imgsources.push('../../assets/img/gallery/domowik_bieszczady_taras.jpg');

    this._imgsources.push('../../assets/img/gallery/zdjecie.png');
    this._imgsources.push('../../assets/img/gallery/zdjecie2.png');
    this._imgsources.push('../../assets/img/gallery/zdjecie3.png');
    this._imgsources.push('../../assets/img/gallery/zdjecie4.png');
    this._imgsources.push('../../assets/img/gallery/zdjecie5.png');
    this._imgsources.push('../../assets/img/gallery/zdjecie6.png');
    this._imgsources.push('../../assets/img/gallery/zdjecie7.png');
    this._imgsources.push('../../assets/img/gallery/zdjecie8.png');
    this._imgsources.push('../../assets/img/gallery/zdjecie9.png');
    this._imgsources.push('../../assets/img/gallery/zdjecie10.png');
    this._imgsources.push('../../assets/img/gallery/zdjecie11.png');
    this._imgsources.push('../../assets/img/gallery/zdjecie12.png');
    this._imgsources.push('../../assets/img/gallery/zdjecie13.png');
    this._imgsources.push('../../assets/img/gallery/zdjecie14.png');
    this._imgsources.push('../../assets/img/gallery/zdjecie21.png');
    this._imgsources.push('../../assets/img/gallery/zdjecie22.png');
    this._imgsources.push('../../assets/img/gallery/zdjecie17.png');
    this._imgsources.push('../../assets/img/gallery/zdjecie18.png');
    this._imgsources.push('../../assets/img/gallery/zdjecie19.png');
    this._imgsources.push('../../assets/img/gallery/zdjecie20.png');
    // this._imgsources.push('../../assets/img/gallery/zdjecie21.png');
    // this._imgsources.push('../../assets/img/gallery/zdjecie22.png');



    for (let i = 0; i < this._imgsources.length; i++) {
      const src = this._imgsources[i];
      const album = {
         src: src,
      };
 
      this._albums.push(album);
    }
  }
 
  open(index: number): void {
    this._lightbox.open(this._albums, index);
  }
 
  close(): void {
    this._lightbox.close();
  }

  ngOnInit(): void {
  }

}
