import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-prices',
  template: `
    <section id="prices" style="position: relative;" class="pb-5">
      <div id="prices-anchor" style="position: absolute; top: -86px; left: 0"></div>
      <div class="row px-5">
        <div class="col-lg-6 ">
          <h1 class="py-5">CENNIK</h1>
          <div class="vertical-center">
          <p>
            Aktualne ceny i dostępność można sprawdzić klikając <a href="https://www.airbnb.pl/rooms/46487777?federated_search_id=801ea68e-dd7f-4954-82e1-7242e9b5047f&source_impression_id=p3_1644078399_TsVqABvtE8xe%2B3Kt" target="_blank">tutaj</a>.<br>
          </p>
          <p>
            Przy rezerwacji bezpośrednio przez naszą stronę nie doliczmy opłaty serwisowej.
          </p>
          <p>
            Przy rezerwacji powyżej 7 dni oferujemy 5% rabatu na cały pobyt.
          </p>
          <p>
            Dom można wynająć min. na 3 doby.
          </p>
          </div>
        </div>
      </div>
    </section>
  `,
  styles: [
    `
    li{
      font-family: 'lato-light';
      font-size: calc(16px + 0.5vw);
    }
    @media (min-width: 992px) { 
      #prices {
        background-image: url("../../assets/img/domowik_dom_bieszczady_cennik.png");
        background-size:     50% 85%;
        background-repeat:   no-repeat;
        background-position: right center;
      }
    }
    `
  ]
})
export class PricesComponent implements OnInit {

  constructor() {}

  ngOnInit(): void {
  }

}
