import {Component, OnInit, ViewChild} from '@angular/core';


@Component({
  selector: 'app-contact',
  template: `
    <section id="contact" style="position: relative;">
      <div id="contact-anchor" style="position: absolute; top: -86px; left: 0"></div>
      <div id="contact-text" class="row pl-5 pr-5 mr-3 pb-3">
        <div class="col-lg-6">
          <h1 class="py-5">KONTAKT</h1>
          <p>
            Jałowe 3i, 38-700 Ustrzyki Dolne
          </p>
          <p>
            49°24’37.0”N 22°37’53.2”E<br>
            49.410278, 22.631449
          </p>
          <p>
            tel. <a href="tel:+48509372014">+48 509 372 014</a><br>
            e-mail: <a href="mailto:rezerwacje@domowik-bieszczady.pl">rezerwacje@domowik-bieszczady.pl</a>
          </p>
        </div>
      </div>
      <iframe class="row mx-auto" src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAo_mYPnn954DArYiod6ANfyqZ9WCq52C0&q=Domowik-+dom+w+Bieszczadach&zoom=16&maptype=satellite" width="100%" [ngStyle]="{'height.px' : result}" frameborder="0"></iframe>
    </section>
  `,
  styles: [
    `
    a {
      font-family: 'lato-light';
    }

    iframe {
      min-height: 400px;
    }
    `
  ]
})
export class ContactComponent implements OnInit {

  latitude = 49.410278;
  longitude = 22.631449;

  result: number | undefined;

  constructor() {}

  ngOnInit() {

    let totalHeight = document.getElementById('contact')?.offsetHeight;
    let contactHeight = document.getElementById('contact-text') ?.offsetHeight;
    // @ts-ignore
    this.result = totalHeight - contactHeight;
  }

}

