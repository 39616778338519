import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  template: `
  <app-header></app-header>
  <app-home></app-home>
  <app-dom></app-dom>
  <app-galery></app-galery>
  <app-prices></app-prices>
  <app-regulations></app-regulations>
  <app-contact></app-contact>
  `,
  styles: []
})
export class AppComponent {
  title = 'Domowik - dom w Bieszczadach';
}
